import {Col, Container, Row} from "react-bootstrap";
import {memo, useEffect, useState} from "react";

import Crowdsale from '../contract/Crowdsale.json';
import oLU from '../contract/OLU.json';
import {crowdsale_addr, oLU_addr} from '../contract/addresses'
import {useWeb3React} from "@web3-react/core";
import Web3Modal from 'web3modal'
import {ethers} from 'ethers'

function User() {
    const {
        connector,
        library,
        account,
        chainId,
        activate,
        deactivate,
        active,
        errorWeb3Modal
    } = useWeb3React();

    const [users, setUsers] = useState([])

    const loadProvider = async () => {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            return provider.getSigner();
        } catch (e) {
        }
    };

    const userList = users.map((data) =>
        <tr>
            <th scope="row">{data.row}</th>
            <td>{data.address}</td>
            <td>{data.balance}</td>
            <td>{data.date}</td>
        </tr>
    );

    const GetInfo = async (e) => {
        try {
            let signer = await loadProvider()
            let crowdsaleCon = new ethers.Contract(crowdsale_addr, Crowdsale, signer)
            let OLU = new ethers.Contract(oLU_addr, oLU, signer)
            let totalUsers = await crowdsaleCon.totalUsers()
            let _users = []


            for (let index = 0; index < totalUsers.toNumber(); index++) {
                let user = await crowdsaleCon.accounts(index)
                let detail = await crowdsaleCon.purchaseDetails(user)
                let time = detail[0].toNumber()
                let date = new Date(time * 1000).toLocaleDateString("en-US")
                date = date.toString()
                let bal = detail[2].toString()
                bal = ethers.utils.formatEther(bal.toString(), 18)
                let obj = {
                    row: index + 1,
                    address: user,
                    balance: bal,
                    date: date
                }
                _users.push(obj)
            }
            setUsers(_users)

        } catch (e) {
            console.log("user", e)
        }
    }


    useEffect(async () => {
        try {
            async function fetchData() {
                // You can await here
                const response = await GetInfo()
                // ...
            }

            fetchData();
        } catch (error) {

        }

    }, activate, account)
    return <>
        <Container fluid className="main-height">
            <Row>
                <Col lg={12} md={12}>
                    <div className="custom-chart-margin">
                        <h5 className="section-title">Users</h5>
                        <div className="overflow-auto">
                            <table className="table table-striped mt-3">
                                <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Address</th>
                                    <th scope="col">Token Hold</th>
                                    <th scope="col">Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {userList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
}

export default memo(User);