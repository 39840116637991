import axios from "axios";

const URL = process.env.REACT_APP_API_BASE_URL

export const getMonthData = async (month)=> {
    
    console.log(month)
    const result = await axios.get(`${URL}/users/`+month);
    if(result.data[0])
    return result.data[0];
    else
    return 0

}

export const createMonthData = async (addresses , balance , time)=> {
    
    
    const result = await axios.post(`${URL}/users/`, {body :{addresses , balance , time}});
    console.log("api :" , result)
    if(result.data)
    return result.data;
    else
    return 0

}


//@ Blog post API
export const addBlogApi = async ( image , title, description)=> {
  console.log("addblogAPI==>",image ,title,description);
    const result = await axios.post(`${URL}/blogs/add`, {title, description, image}, {
       headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    console.log("api :" , result)
    if(result.data)
    return result.data;
    else
    return 0
}

export const getBlogs = async ()=> {

    const result = await axios.get(`${URL}/blogs`);
    if(result.data)
    return result.data;
    else
    return 0  
}

//@ Blog post API
export const deleteBlog = async (id)=> {

    const result = await axios.delete(`${URL}/blogs/delete/${id}`);
    if(result.data)
    return result.data;
    else
    return 0
}

export const editBlog = async (id,image,title,description)=> {

    console.log(id,image,title,description);

    const result = await axios.put(`${URL}/blogs/update/${id}`, 
    {image,title, description},
    {
        headers: {
             'Content-Type': 'multipart/form-data'
         }
     });
    if(result.data)
    return result.data;
    else
    return 0
}

// {"addresses" : ["0x48d3788eacEe5235Fa855fE23F22A77d779e9B1C","0x48d3788eacEe5235Fa855fE23F22A77d779e9B1C"], "balance" : ["20","30"],
// "time" : 1653486432 ,
//     "month" : 3
// }